// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import '../../styles/global.css'

const colors = {
  //   brand: {
  primary: '#25e6e5',
  secondary: '#5beceb',
  black: {
    50: '#f2f2f2',
    100: '#d9d9d9',
    200: '#bfbfbf',
    300: '#a6a6a6',
    400: '#8c8c8c',
    500: '#737373',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#0d0d0d',
  },
  Flamingo: {
    50: '#ffe8e1',
    100: '#ffc1b3',
    200: '#fb9b83',
    300: '#f87353',
    400: '#f54c23',
    500: '#dc330a',
    600: '#ac2707',
    700: '#7b1a04',
    800: '#4c0e00',
    900: '#1f0200',
  },
  //   },
}

// const breakpoints = createBreakpoints({
//   xs: '414px',
//   sm: '567px',
//   md: '768px',
//   lg: '960px',
//   xl: '1200px',
// })

let customTheme = extendTheme({
  colors,
  //   breakpoints,
})

export default customTheme
